 @charset "utf-8";

//============================================================
//    基本設定
//============================================================

// フォント
$font: -apple-system, BlinkMacSystemFont, "Helvetica Neue", 'Noto Sans JP', "Original Yu Gothic", "Yu Gothic", YuGothic, Verdana, Meiryo, "M+ 1p", sans-serif;

// Webフォント
$webfont1: 'Montserrat', sans-serif;

// カラーテーマ5種
$cMain: #fbb901;
$c2: #ffeab3;
$c3: #fdf0e1;
$c4: #d59e03;
$c5: #d1dbbd;

// グレーテーマ5種
$grey1: #e1e2e7;
$grey2: #f0f0f3;
$grey3: #999eae;
$black1: #101010;
$black2: #585858;

//警告色
$attention: #f33434;
$attention2: #fde1e1;

// サイト横幅
$w-site : 1080px;

//ブレイクポイント
$w-sp: 767px;

// 標準テキストカラー
$tc: #343e5d;

// 標準背景色
$bg: #fff;

// 標準リンクカラー
$lc: #69c;

// 標準イージング
$ease: cubic-bezier(0.215, 0.61, 0.355, 1);
