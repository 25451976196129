@charset "utf-8";
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,500,700&display=swap&subset=japanese');
//============================================================
//    メインCSS
//============================================================
@import "components/reset";
@import "components/font";
@import "setting";
@import "components/form";
@import "components/base";


.mt0{
  margin-top: 0px !important;
}
.mt20{
  margin-top: 20px !important;
}
.mr40{
  margin-right: 40px !important;
}

.pt0{
  padding-top: 0 !important;
}




/*--------------------------------------------
標準設定
---------------------------------------------*/
body {
  max-width: 750px;
  width: 100%;
  background-color: $bg;
  color: $tc;
  font-size: 16px;
  line-height: 1.6;
	font-family: $font;
  -webkit-font-smoothing: antialiased;
  margin: 0 auto;
}

.contents{
  min-height: 240px;
  padding-top: 32px;
  padding-right: 5%;
  padding-left: 5%;
}
.separate-padding{
  .contents {
    padding-right: 0;
    padding-left: 0;

    .form-wrap{
      padding-right: 5%;
      padding-left: 5%;
    }
  }
}


//標準リンク処理等
a {
  color: $tc;
  text-decoration: none;
}

img{
  max-width: 100%;
}


//ボタン
.btn{
  position: relative;
  display: block;
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  line-height: 1;
  padding-top: 18px;
  padding-bottom: 18px;
  border-radius: 5px;
  &.submit{
    width: calc(100% - 5px);
    color: #fff;
    background: $cMain;
    &:after{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: calc(100% + 5px);
      height: calc(100% + 5px);
      background: $c4;
      border-radius: 5px;
      z-index: -1;
    }

    &.unsubscribe{
      margin-top: 30px;
    }

    &.disabled{
      color: #fff;
      background: $grey1;
      cursor: default;
      &:after{
        background: $grey3;
      }
    }
  }

  &.back{
    width: calc(100% - 5px);
    color: #fff;
    background: gray;
    margin-top: 24px;
    &:after{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: calc(100% + 5px);
      height: calc(100% + 5px);
      background: #717171;
      border-radius: 5px;
      z-index: -1;
    }

  }

  &.log-out{
    width: calc(100% - 40px);
    color: $tc;
    background: $grey2;
    margin: 0 auto;
  }

}


//ボーダー
.bdt-grey{
  border-top: 1px solid $grey1;
}



/*--------------------------------------------
ヘッダー
---------------------------------------------*/
.header{
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;

  .logo-header{
    width: 107px;
    a{
      line-height: 1;
    }
  }
}
.u-layer{
  .header{
    justify-content: flex-start;
    align-items: center;
    height: 60px;
    padding: 0;
    background: $cMain;

    .logo-header{
      display: flex;
      align-items: center;
      width: calc(100% - 60px);
      padding-left: 5%;
    }

    .menu-trigger{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 60px;
      border-left: 1px solid #fff;
    }
  }
}




/*--------------------------------------------
SPメニュー
---------------------------------------------*/
.sp-menu{
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100% - 60px);
  height: 100vh;
  background: #fff;
  transform: translate3d(100%, 0, 0);
  transition: transform .5s $ease;
  z-index: 10001;

  .customer-info{
    display: flex;
    align-items: center;
    padding: 35px 5%;
    .icon{
      width: 54px;
    }
    .name-wrap{
      padding-left: 5%;
      .name{
        font-size: 21px;
        font-weight: bold;
      }
      .kana{
        font-size: 16px;
      }
    }
  }

  .menu-list{
    border-bottom: 1px solid $grey1;
    li{
      a{
        position: relative;
        display: block;
        font-size: 18px;
        padding: 16px 20px;
        border-top: 1px solid $grey1;
        &:after{
          content: '';
          position: absolute;
          top: 50%;
          right: 20px;
          display: inline-block;
          width: 8px;
          height: 11px;
          background: url(/common/img/arw-r-g.png) no-repeat center /contain;
          transform: translateY(-50%);
        }
      }
    }
  }

  .btn{
    font-size: 16px;
    margin-top: 40px;
  }

  &.show{
    transform: translate3d(0, 0, 0);
  }
}
.overlay{
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, .6);
  opacity: 0;
  visibility: hidden;
  z-index: -10;

  .close{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 60px;
    height: 100vh;
    padding-top: 15px;
  }

  &.show{
    opacity: 1;
    visibility: visible;
    z-index: 10000;
  }
}




/*--------------------------------------------
フッター
---------------------------------------------*/
.footer{
  padding: 20px 5%;
  border-top: 1px solid $grey1;
  margin-top: 60px;

  .logo-footer{
    text-align: center;
    margin-top: 20px;
    img{
      max-width: 233px;
      width: 100%;
    }
  }

  .footer-tel{
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 49%;
      background: $c2;
      border-radius: 4px;
      text-align: center;
      padding: 16px 5px;
      i{
        margin-right: 5px;
      }
    }
    dl{
      display: flex;
      justify-content: center;
      align-items: center;
      .ttl{
        display: flex;
        align-items: center;
        font-size: 16px;
        padding-right: 5%;
        .icon{
          margin-right: 10px;
        }
      }
      .num{
        padding-left: 5%;
        border-left: 1px solid #ddc9b3;
        a{
          font-size: 24px;
          font-weight: bold;
        }
        .department{
          font-size: 12px;
          text-align: right;
        }
      }
    }
  }

  .copyright{
    font-size: 12px;
    text-align: center;
    margin-top: 36px;
  }
}




/*--------------------------------------------
メインビジュアル
---------------------------------------------*/
.mv{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 210px;
}
.page-login{
  .mv{
    background: url(/common/img/login/mv.png) no-repeat center / cover;
    .mv-ttl{
      width: 272px;
    }
  }
}




/*--------------------------------------------
ページタイトル
---------------------------------------------*/
.page-ttl{
  font-size: 24px;
  font-weight: bold;
  padding: 12px 5%;
  border-bottom: 1px solid $c2;
}




/*--------------------------------------------
ログインタイトル
---------------------------------------------*/
.ttl-02.login{
  width: 120px;
  height: 120px;
  font-size: 24px;
  font-weight: bold;
  color: $cMain;
  text-align: center;
  border-radius: 50%;
  background: #fff;
  padding-top: 20px;
  padding-bottom: 25px;
  margin: -30px auto 0;
  img{
    display: inline-block;
    width: 30px;
    height: auto;
    margin-bottom: 10px;
  }
  .txt{
    display: block;
  }
}




/*--------------------------------------------
ページ概要
---------------------------------------------*/
.page-summary{
  padding-right: 5%;
  padding-left: 5%;
  margin-top: 25px;
}


/*--------------------------------------------
エラー警告文
---------------------------------------------*/
.error-anno{
  display: none;
  &.show{
    display: block;
    width: 90%;
    padding: 16px 5%;
    border: 2px solid $attention;
    border-radius: 4px;
    margin: 0 auto;
    .ttl-03{
      display: flex;
      width: 100%;
      align-items: center;
      font-size: 18px;
      color: $attention;
      .icon{
        padding-right: 10px;
      }
    }
    .txt{
      font-size: 16px;
      margin-top: 10px;
      line-height: 1.8;
    }
  }
}
.u-layer{
  .error-anno{
    width: 90%;
    margin: 30px auto 0;
  }
}


/*--------------------------------------------
フォーム
---------------------------------------------*/
.form-wrap{
  margin-top: 30px;

  .login-form{
    margin-top: 30px;
  }

  .customer-info{
    padding: 16px 5%;
    border: 1px solid $cMain;
    background: $c3;
    border-radius: 4px;

    .ttl-03{
      font-size: 18px;
    }

    p{
      margin-top: 6px;
      + p{
        margin-top: 4px;
      }
    }
  }

  .icon-req{
    font-size: 13px;
    color: #fff;
    line-height: 1;
    padding: 2px 6px;
    background: $attention;
    border-radius: 4px;
    margin-right: 10px;
  }

  .block{
    margin-top: 24px;
    .ttl{
      font-size: 18px;
    }
    .form{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 4px;

      &.select{
        .select-wrap{
          position: relative;
          width: 100%;
          &:after{
            content: '';
            position: absolute;
            top: 50%;
            right: 6%;
            display: inline-block;
            width: 12px;
            height: 7.5px;
            background: url(/common/img/arw-b.png) no-repeat;
            -webkit-background-size: contain;
            background-size: contain;
            transform: translateY(-50%);
          }
        }

        &.relate{
          .select-wrap{
            width: 60%;
          }
        }

        &.birth{
          .select-wrap, .year-wrap{
            width: 31%;
            &:after{
              right: 10%;
            }
          }
        }
      }

      &.name{
        > div{
          display: flex;
          flex-wrap: wrap;
          width: 48%;

        }
      }

      &.radio{
        justify-content: flex-start;
      }

      &.rules{
        margin-top: 40px;

        .rules-box{
          font-size: 14px;
          padding: 18px 12px 18px 18px;
          background: #fef5d9;
          border: 2px solid $cMain;
          border-radius: 6px;

          .rules-inner{
            width: 100%;
            height: 156px;
            overflow-y: auto;
          }
        }

        .rules-summary{
          margin-top: 20px;
          line-height: 1.8;
        }

        .rules-list{
          margin-top: 16px;

          ul li{
            margin-top: 8px;

            .link{
              text-decoration: underline;
            }

            > ul{
              margin-top: 4px;

              li{
                padding-left: 1em;
                margin-top: 4px;
              }
            }
          }
        }
      }

      input[type='text'], input[type='password'], input[type='email'], textarea, select{
        display: block;
        width: 100%;
        font-size: 16px;
        margin-top: 4px;
      }

      .error-txt{
        display: none;
      }

      &.error-wrap{
        > input[type='text'], > input[type='password'], > input[type='email'], > textarea, > select{
          display: block;
          width: 100%;
          font-size: 16px;
          border: 1px solid $attention;
          background: $attention2;
          margin-top: 4px;
        }

        .select-wrap.error{
          input[type='text'], input[type='password'], input[type='email'], textarea, select{
            display: block;
            width: 100%;
            font-size: 16px;
            border: 1px solid $attention;
            background: $attention2;
            margin-top: 4px;
          }
        }

        .error{
          input[type='text'], input[type='password'], input[type='email'], textarea, select{
            display: block;
            width: 100%;
            font-size: 16px;
            border: 1px solid $attention;
            background: $attention2;
            margin-top: 4px;
          }
        }

        .error-txt{
          display: block;
          width: 100%;
          color: $attention;
        }
      }
    }

    .text{
      margin-top: 2px;
      &.name, &.kana{
        span + span{
          margin-left: 16px;
        }
      }
    }
  }

  .anno-txt{
    display: block;
    width: 100%;
    color: #adb0b9;
  }

  .agree, .retention{
    position: relative;
    display: table;
    width: auto;
    text-align: center;
    font-size: 16px;
    vertical-align: middle;
    margin: 30px auto 0;
    .text{
      &:before{
        content: '';
        position: relative;
        top: 7px;
        display: inline-block;
        width: 30px;
        height: 30px;
        border: 1px solid $tc;
        border-radius: 4px;
        margin-right: 15px;
      }
      &:after{
        content: '';
        position: absolute;
        top: 52%;
        left: 20px;
        display: none;
        width: 8px;
        height: 13px;
        border-right: 3px solid #343e5d;
        border-bottom: 3px solid #343e5d;
        transform: translate(calc(-50% - 5px), calc(-50% + 1px)) rotate(45deg);
      }
    }
    input[type="checkbox"]:checked{
      + .text{
        //&:before{
        //  content: '';
        //  position: absolute;
        //  top: 50%;
        //  left: 50%;
        //  display: inline-block;
        //  width: 8px;
        //  height: 3px;
        //  background: $tc;
        //  transform: translate(calc(-50% - 5px), calc(-50% + 1px)) rotate(45deg);
        //}
        &:after{
          display: block;
        }
      }
    }
    /*.box{
      position: relative;
      display: inline-block;
      width: 30px;
      height: 30px;
      border: 1px solid $tc;
      border-radius: 4px;
      margin-right: 15px;
    }
    input[type="checkbox"]:checked{
      + .box{
        &:before{
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          display: inline-block;
          width: 8px;
          height: 3px;
          background: $tc;
          transform: translate(calc(-50% - 5px), calc(-50% + 1px)) rotate(45deg);
        }
        &:after{
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          display: inline-block;
          width: 16px;
          height: 3px;
          background: $tc;
          transform: translate(calc(-50% + 3px), -50%) rotate(-45deg);
        }
      }
    }*/
    a{
      text-decoration: underline;
    }
  }

  .checkPass{
    position: relative;
    font-size: 13px;
    margin-top: 2px;

    label{
      &:before{
        content: '';
        position: relative;
        top: 6px;
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 1px solid #343e5d;
        border-radius: 4px;
        margin-right: 8px;
      }
      &:after{
        content: '';
        position: absolute;
        top: 54%;
        left: 16px;
        display: none;
        width: 6px;
        height: 10px;
        border-right: 2px solid #343e5d;
        border-bottom: 2px solid #343e5d;
        transform: translate(calc(-50% - 5px), calc(-50% + 1px)) rotate(45deg);
      }
    }

    input[type="checkbox"]:checked{
      + label{
        &:after{
          display: block;
        }
      }
    }
  }

  .radio-wrap{
    display: flex;
    align-items: center;
    font-size: 16px;

    &:before{
      content: '';
      position: relative;
      display: inline-block;
      width: 30px;
      height: 30px;
      background: $grey1;
      border: 10px solid $grey1;
      border-radius: 50%;
      margin-right: 8px;
    }

    + input{
      margin-left: 40px;
    }

    //.box{
    //  position: relative;
    //  display: inline-block;
    //  width: 30px;
    //  height: 30px;
    //  background: $grey1;
    //  border: 10px solid $grey1;
    //  border-radius: 50%;
    //  margin-right: 8px;
    //}
  }
  input[type="radio"]:checked{
    + .radio-wrap{
      &:before{
        background: $tc;
      }
    }
  }

  .btn.submit{
    margin-top: 30px;
  }

  .forget{
    text-align: right;
    margin-top: 18px;
    a{
      position: relative;
      display: inline-block;
      font-size: 16px;
      padding-bottom: 5px;
      padding-left: 14px;
      border-bottom: 1px solid $tc;
      .icon{
        position: absolute;
        top: 46%;
        left: 0;
        transform:  translateY(-50%);
      }
    }
  }
}


/*--------------------------------------------
ログインページ
---------------------------------------------*/
.page-login{
  .contents{
    padding-top: 0;
  }
  .form-wrap{
    margin-top: 0;
  }
}


/*--------------------------------------------
施術内容
---------------------------------------------*/
.page-treatment{
  .contents{
    padding-top: 40px;
    padding-bottom: 40px;
    background: $grey2;
    
    .treatment-info{
      padding: 24px 5%;
      background: #fff;
      border-radius: 5px;

      + .treatment-info{
        margin-top: 30px;
      }

      .date-wrap{
        display: flex;
        font-weight: bold;
      }
      .img{
        margin-top: 16px;
      }
      .trimmer{
        margin-top: 20px;
      }
      .charge{
        padding-top: 20px;
        border-top: 1px solid $c2;
        margin-top: 22px;

        .ttl-03{
          color: $cMain;
        }
        .text{
          margin-top: 8px;
        }
      }
    }
  }

  .page-nation-wrap{
    padding: 30px 5% 0;

    .count{
      text-align: center;
    }

    .page-num-list{
      display: flex;
      justify-content: center;
      margin-top: 16px;
      .list{
        width: 42px;
        height: 42px;

        + .list{
          margin-left: 10px;
        }

        a{
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          background: $grey1;
          border-radius: 4px;
        }

        &.current{
          a{
            background: $cMain;
            color: #fff;
          }
        }
      }
    }

    .nav-send{
      display: flex;
      justify-content: center;
      margin-top: 20px;

      .list{
        width: 48%;
        a{
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          padding-top: 12px;
          padding-bottom: 12px;
          background: $grey1;
          border-radius: 4px;
          &.prev{
            &:after{
              content: '';
              position: absolute;
              top: 50%;
              left: 15px;
              width: 6px;
              height: 10px;
              background: url(/common/img/arw-l.png) no-repeat center / contain;
              transform: translateY(-50%);
            }
          }
          &.next{
            &:after{
              content: '';
              position: absolute;
              top: 50%;
              right: 15px;
              width: 6px;
              height: 10px;
              background: url(/common/img/arw-r.png) no-repeat center / contain;
              transform: translateY(-50%);
            }
          }
        }

        + .list{
          margin-left: 4%;
        }
      }
    }
  }
}


/*--------------------------------------------
よくあるご質問
---------------------------------------------*/
.page-faq{

  .faq-area{
     + .faq-area{
      margin-top: 32px;
    }

    .ttl-03{
      font-size: 18px;
      font-weight: normal;
    }

    .faq-box{
      border: 1px solid #e1e2e7;
      border-radius: 5px;
      padding: 0;
      overflow: hidden;
      &:first-of-type{
        margin-top: 18px;
      }
      + .faq-box{
        margin-top: 30px;
      }

      .ttl-04{
        display: flex;
        padding: 16px 18px;
        border-bottom: 1px solid #e1e2e7;
        .icon{
          display: flex;
          justify-content: center;
          align-items: center;
          width: 30px;
          height: 30px;
          background: $cMain;
          border-radius: 50%;
          margin-right: 10px;
          i{
            color: #fff;
            font-size: 15px;
          }
        }

        .text{
          width: calc(100% - 40px);
          font-size: 18px;
          font-weight: bold;
        }
      }

      .answer-box{
        padding: 24px 22px;
        .inner{
          + .inner{
            margin-top: 30px;
          }

          .ttl-05{
            display: flex;
            width: 100%;
            font-size: 16px;
            font-weight: bold;

            .icon{
              position: relative;
              top: 5px;
              margin-right: 4px;
            }

            + .text{
              margin-top: 4px;
            }
          }

          .text{
            line-height: 1.8;
            a{
              text-decoration: underline;
            }
          }
        }
      }

      .button-wrap{
        width: 100%;
        padding: 0;
        .button{
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          font-size: 13px;
          padding: 12px 0;
          margin: 0;
          border: none;

          .icon{
            margin-right: 10px;
            font-weight: 900;
          }
          &.open{
            background: #e1e2e7;
          }
          &.close{
            display: none;
            color: #fff;
            background: $cMain;
          }
        }
      }

      &.show{
        background: #fef5d9;
        border-color: $cMain;

        .ttl-04{
          border-color: $cMain;
        }

        .button-wrap{
          width: 100%;
          .button{

            &.open{
              display: none;
            }

            &.close{
              display: flex;
            }

            &.show{
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }

      }
    }
  }
}




/*--------------------------------------------
SE
---------------------------------------------*/
@media screen and (max-width: 320px) {
  /*--------------------------------------------
  ヘッダー(se)
  ---------------------------------------------*/
  .u-layer{
    .header{

      .logo-header{
        img{
          width: 232px;
        }
      }
    }
  }


  /*--------------------------------------------
  SPメニュー(se)
  ---------------------------------------------*/
  .sp-menu{
    .customer-info{
      .name-wrap{
        .name{
          font-size: 16px;
        }
        .kana{
          font-size: 12px;
        }
      }
    }
  }


  /*--------------------------------------------
  ページタイトル(se)
  ---------------------------------------------*/
  .page-ttl{
    font-size: 18px;
  }


  /*--------------------------------------------
  フッター(se)
  ---------------------------------------------*/
  .footer{

    .footer-tel{
      padding: 10px 5px;
      dl{
        .ttl{
          padding-right: 10px;
        }
        .num{
          padding-left: 10px;
        }
      }

      a{
        font-size: 14px;
      }
    }
  }

}

//============================================================
//    その他部分
//============================================================
@import "addon/print";  //印刷
