@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,500,700&display=swap&subset=japanese");
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

img {
  vertical-align: top;
  font-size: 0;
  line-height: 0;
}

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

input[type="button"],
input[type="submit"],
textarea {
  border-radius: 0;
  -webkit-appearance: none;
}

select {
  border-radius: 0;
}

a,
input,
label {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@font-face {
  font-family: "Original Yu Gothic";
  src: local("Yu Gothic");
  font-weight: 300;
}

@font-face {
  font-family: "Original Yu Gothic";
  src: local("Yu Gothic");
  font-weight: 500;
}

@font-face {
  font-family: "Original Yu Gothic";
  src: local("Yu Gothic");
  font-weight: bold;
}

@font-face {
  font-family: "Helvetica Neue";
  src: local("Helvetica Neue Regular");
  font-weight: 100;
}

@font-face {
  font-family: "Helvetica Neue";
  src: local("Helvetica Neue Regular");
  font-weight: 200;
}

/* IE10以上 */
@media all and (-ms-high-contrast: none) {
  html {
    font-family: Verdana, Meiryo, sans-serif;
  }
}

@media all and (-ms-high-contrast: active) {
  html {
    font-family: Verdana, Meiryo, sans-serif;
  }
}

/*input:not([type]), input[type="text"], input[type="password"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="month"], input[type="week"], input[type="email"], input[type="number"], input[type="search"], input[type="tel"], input[type="time"], input[type="url"], input[type="color"], textarea {
	-webkit-appearance: none;
	-moz-appearance: none;
	display: block;
	box-sizing: border-box;
	margin: 0;
	padding: 10px;
	transition: all 300ms $ease;
	&:focus {
		background: #fafafa;
		outline: none;
	}
}

select {
	-webkit-appearance: none !important;
	-moz-appearance: none !important;
	border: 1px solid #ccc;
	padding: 10px 6% 10px 10px;
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
	cursor: pointer;
	background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAGFBMVEUAAAAZJCYWISIYIyQYIiUWIiIYIyQYIiXh0rToAAAACHRSTlMA/hTRqiinvwFkb0sAAABCSURBVHjajchBDsAgDAPBOJDw/x+XVN0icQGfPGs3i+2GEqeiLCXWLENzvdzrDfP2ls/NjfKbgimYglfBq2Dm+LwHguMA235EdKYAAAAASUVORK5CYII=") 94% center no-repeat;
	transition: all 300ms $ease;
	&:focus {
		border:1px solid #aaa;
		background: #fafafa url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAGFBMVEUAAAAZJCYWISIYIyQYIiUWIiIYIyQYIiXh0rToAAAACHRSTlMA/hTRqiinvwFkb0sAAABCSURBVHjajchBDsAgDAPBOJDw/x+XVN0icQGfPGs3i+2GEqeiLCXWLENzvdzrDfP2ls/NjfKbgimYglfBq2Dm+LwHguMA235EdKYAAAAASUVORK5CYII=") 94% center no-repeat;
		outline: none;
	}
}

input[type=radio], input[type=checkbox] {
	display: none;
}

.radio, .checkbox {
	box-sizing: border-box;
	transition: all 300ms $ease;
	position: relative;
	display: inline-block;
	margin: 0 10px 0 0;
	padding: 10px 10px 10px 42px;
	border-radius: 4px;
	background: #f3f3f3;
	vertical-align: middle;
	cursor: pointer;
	&:hover {
		background-color: darken(#f3f3f3,3%);
	&:after {
		border-color: #53b300;
	}
}

&:after {
	transition: all 300ms $ease;
	position: absolute;
	top: 50%;
	left: 15px;
	display: block;
	margin-top: -10px;
	width: 16px;
	height: 16px;
	border: 2px solid #bbb;
	border-radius: 4px;
	content: '';
	}
}
.radio {
	&:before {
		transition: all 300ms $ease;
		position: absolute;
		top: 50%;
		left: 20px;
		display: block;
		margin-top: -5px;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background-color:  #53b300;
		content: '';
		opacity: 0;
		input[type=radio]:checked + & {
			opacity: 1;
		}
	}
}

.checkbox {
	&:before {
		transition: all 300ms $ease;
		position: absolute;
		top: 50%;
		left: 21px;
		display: block;
		margin-top: -7px;
		width: 5px;
		height: 9px;
		border-right: 3px solid  #53b300;
		border-bottom: 3px solid  #53b300;
		content: '';
		opacity: 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
		input[type=checkbox]:checked + & {
			opacity: 1;
		}
	}
}*/
input, button, select, textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
  outline: none;
}

textarea {
  resize: vertical;
}

input[type='checkbox'], input[type='radio'] {
  display: none;
}

input[type='submit'], input[type='button'], input[type='email'], label, button, select {
  cursor: pointer;
}

select::-ms-expand {
  display: none;
}

input::placeholder {
  color: #343e5d;
}

/* IE */
input:-ms-input-placeholder {
  color: #343e5d;
}

/* Edge */
input::-ms-input-placeholder {
  color: #343e5d;
}

input[type='text'], input[type='password'], input[type='email'], select {
  height: 52px;
  padding: 8px 10px;
  background: #e1e2e7;
  border-radius: 6px;
}

select.error {
  color: #343e5d !important;
}

input[type='check'] {
  display: none;
}

input, select, textarea, .checkbox {
  width: 100%;
}

/*--------------------------------------------
  汎用クラス
---------------------------------------------*/
/*クリアフィックス*/
.cf {
  *zoom: 1;
}

.cf:after {
  content: "";
  display: table;
  clear: both;
}

.cf:before {
  content: "";
  display: table;
}

/*クリアフィックス(簡易)*/
.oh {
  overflow: hidden;
}

/*テキストサイズ変更*/
.fz10 {
  font-size: 10px !important;
}

.fz11 {
  font-size: 11px !important;
}

.fz12 {
  font-size: 12px !important;
}

.fz13 {
  font-size: 13px !important;
}

.fz14 {
  font-size: 14px !important;
}

.fz15 {
  font-size: 15px !important;
}

.fz16 {
  font-size: 16px !important;
}

.fz17 {
  font-size: 17px !important;
}

.fz18 {
  font-size: 18px !important;
}

.fz19 {
  font-size: 19px !important;
}

.fz20 {
  font-size: 20px !important;
}

.fz21 {
  font-size: 21px !important;
}

.fz22 {
  font-size: 22px !important;
}

.fz23 {
  font-size: 23px !important;
}

.fz24 {
  font-size: 24px !important;
}

/*文字の太さ*/
.fwb {
  font-weight: bold !important;
}

.fwn {
  font-weight: normal !important;
}

/*文字の位置*/
.tac {
  text-align: center !important;
}

.tal {
  text-align: left !important;
}

.tar {
  text-align: right !important;
}

/*マージンレフト*/
.mr0 {
  margin-left: 0px !important;
}

.mr5 {
  margin-left: 5px !important;
}

.mr10 {
  margin-left: 10px !important;
}

.mr15 {
  margin-left: 15px !important;
}

.mr20 {
  margin-left: 20px !important;
}

.mr25 {
  margin-left: 25px !important;
}

.mr30 {
  margin-left: 30px !important;
}

/*マージントップ*/
.mr0 {
  margin-top: 0px !important;
}

.mr5 {
  margin-top: 5px !important;
}

.mr10 {
  margin-top: 10px !important;
}

.mr15 {
  margin-top: 15px !important;
}

.mr20 {
  margin-top: 20px !important;
}

.mr25 {
  margin-top: 25px !important;
}

.mr30 {
  margin-top: 30px !important;
}

/*マージンボトム*/
.mb0 {
  margin-bottom: 0px !important;
}

.mb5 {
  margin-bottom: 5px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb15 {
  margin-bottom: 15px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb25 {
  margin-bottom: 25px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.mb35 {
  margin-bottom: 35px !important;
}

.mb40 {
  margin-bottom: 40px !important;
}

.mb45 {
  margin-bottom: 45px !important;
}

.mb50 {
  margin-bottom: 50px !important;
}

.mb55 {
  margin-bottom: 55px !important;
}

.mb60 {
  margin-bottom: 60px !important;
}

/*マージンライト*/
.mr0 {
  margin-right: 0px !important;
}

.mr5 {
  margin-right: 5px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.mr15 {
  margin-right: 15px !important;
}

.mr20 {
  margin-right: 20px !important;
}

.mr25 {
  margin-right: 25px !important;
}

.mr30 {
  margin-right: 30px !important;
}

@media screen and (min-width: 768px) {
  .mb-xs {
    margin-bottom: 5px !important;
  }
  .mb-s {
    margin-bottom: 15px !important;
  }
  .mb-m {
    margin-bottom: 30px !important;
  }
  .mb-l {
    margin-bottom: 60px !important;
  }
  .mb-xl {
    margin-bottom: 80px !important;
  }
}

@media screen and (max-width: 767px) {
  .mb-xs {
    margin-bottom: 5px !important;
  }
  .mb-s {
    margin-bottom: 10px !important;
  }
  .mb-m {
    margin-bottom: 20px !important;
  }
  .mb-l {
    margin-bottom: 40px !important;
  }
  .mb-xl {
    margin-bottom: 50px !important;
  }
}

@media screen and (min-width: 768px) {
  .sp {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .pc {
    display: none !important;
  }
}

.mt0 {
  margin-top: 0px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mr40 {
  margin-right: 40px !important;
}

.pt0 {
  padding-top: 0 !important;
}

/*--------------------------------------------
標準設定
---------------------------------------------*/
body {
  max-width: 750px;
  width: 100%;
  background-color: #fff;
  color: #343e5d;
  font-size: 16px;
  line-height: 1.6;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Noto Sans JP", "Original Yu Gothic", "Yu Gothic", YuGothic, Verdana, Meiryo, "M+ 1p", sans-serif;
  -webkit-font-smoothing: antialiased;
  margin: 0 auto;
}

.contents {
  min-height: 240px;
  padding-top: 32px;
  padding-right: 5%;
  padding-left: 5%;
}

.separate-padding .contents {
  padding-right: 0;
  padding-left: 0;
}

.separate-padding .contents .form-wrap {
  padding-right: 5%;
  padding-left: 5%;
}

a {
  color: #343e5d;
  text-decoration: none;
}

img {
  max-width: 100%;
}

.btn {
  position: relative;
  display: block;
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  line-height: 1;
  padding-top: 18px;
  padding-bottom: 18px;
  border-radius: 5px;
}

.btn.submit {
  width: calc(100% - 5px);
  color: #fff;
  background: #fbb901;
}

.btn.submit:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: calc(100% + 5px);
  height: calc(100% + 5px);
  background: #d59e03;
  border-radius: 5px;
  z-index: -1;
}

.btn.submit.unsubscribe {
  margin-top: 30px;
}

.btn.submit.disabled {
  color: #fff;
  background: #e1e2e7;
  cursor: default;
}

.btn.submit.disabled:after {
  background: #999eae;
}

.btn.back {
  width: calc(100% - 5px);
  color: #fff;
  background: gray;
  margin-top: 24px;
}

.btn.back:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: calc(100% + 5px);
  height: calc(100% + 5px);
  background: #717171;
  border-radius: 5px;
  z-index: -1;
}

.btn.log-out {
  width: calc(100% - 40px);
  color: #343e5d;
  background: #f0f0f3;
  margin: 0 auto;
}

.bdt-grey {
  border-top: 1px solid #e1e2e7;
}

/*--------------------------------------------
ヘッダー
---------------------------------------------*/
.header {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.header .logo-header {
  width: 107px;
}

.header .logo-header a {
  line-height: 1;
}

.u-layer .header {
  justify-content: flex-start;
  align-items: center;
  height: 60px;
  padding: 0;
  background: #fbb901;
}

.u-layer .header .logo-header {
  display: flex;
  align-items: center;
  width: calc(100% - 60px);
  padding-left: 5%;
}

.u-layer .header .menu-trigger {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-left: 1px solid #fff;
}

/*--------------------------------------------
SPメニュー
---------------------------------------------*/
.sp-menu {
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100% - 60px);
  height: 100vh;
  background: #fff;
  transform: translate3d(100%, 0, 0);
  transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  z-index: 10001;
}

.sp-menu .customer-info {
  display: flex;
  align-items: center;
  padding: 35px 5%;
}

.sp-menu .customer-info .icon {
  width: 54px;
}

.sp-menu .customer-info .name-wrap {
  padding-left: 5%;
}

.sp-menu .customer-info .name-wrap .name {
  font-size: 21px;
  font-weight: bold;
}

.sp-menu .customer-info .name-wrap .kana {
  font-size: 16px;
}

.sp-menu .menu-list {
  border-bottom: 1px solid #e1e2e7;
}

.sp-menu .menu-list li a {
  position: relative;
  display: block;
  font-size: 18px;
  padding: 16px 20px;
  border-top: 1px solid #e1e2e7;
}

.sp-menu .menu-list li a:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 20px;
  display: inline-block;
  width: 8px;
  height: 11px;
  background: url(/common/img/arw-r-g.png) no-repeat center/contain;
  transform: translateY(-50%);
}

.sp-menu .btn {
  font-size: 16px;
  margin-top: 40px;
}

.sp-menu.show {
  transform: translate3d(0, 0, 0);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  visibility: hidden;
  z-index: -10;
}

.overlay .close {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 60px;
  height: 100vh;
  padding-top: 15px;
}

.overlay.show {
  opacity: 1;
  visibility: visible;
  z-index: 10000;
}

/*--------------------------------------------
フッター
---------------------------------------------*/
.footer {
  padding: 20px 5%;
  border-top: 1px solid #e1e2e7;
  margin-top: 60px;
}

.footer .logo-footer {
  text-align: center;
  margin-top: 20px;
}

.footer .logo-footer img {
  max-width: 233px;
  width: 100%;
}

.footer .footer-tel {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer .footer-tel a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 49%;
  background: #ffeab3;
  border-radius: 4px;
  text-align: center;
  padding: 16px 5px;
}

.footer .footer-tel a i {
  margin-right: 5px;
}

.footer .footer-tel dl {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer .footer-tel dl .ttl {
  display: flex;
  align-items: center;
  font-size: 16px;
  padding-right: 5%;
}

.footer .footer-tel dl .ttl .icon {
  margin-right: 10px;
}

.footer .footer-tel dl .num {
  padding-left: 5%;
  border-left: 1px solid #ddc9b3;
}

.footer .footer-tel dl .num a {
  font-size: 24px;
  font-weight: bold;
}

.footer .footer-tel dl .num .department {
  font-size: 12px;
  text-align: right;
}

.footer .copyright {
  font-size: 12px;
  text-align: center;
  margin-top: 36px;
}

/*--------------------------------------------
メインビジュアル
---------------------------------------------*/
.mv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 210px;
}

.page-login .mv {
  background: url(/common/img/login/mv.png) no-repeat center/cover;
}

.page-login .mv .mv-ttl {
  width: 272px;
}

/*--------------------------------------------
ページタイトル
---------------------------------------------*/
.page-ttl {
  font-size: 24px;
  font-weight: bold;
  padding: 12px 5%;
  border-bottom: 1px solid #ffeab3;
}

/*--------------------------------------------
ログインタイトル
---------------------------------------------*/
.ttl-02.login {
  width: 120px;
  height: 120px;
  font-size: 24px;
  font-weight: bold;
  color: #fbb901;
  text-align: center;
  border-radius: 50%;
  background: #fff;
  padding-top: 20px;
  padding-bottom: 25px;
  margin: -30px auto 0;
}

.ttl-02.login img {
  display: inline-block;
  width: 30px;
  height: auto;
  margin-bottom: 10px;
}

.ttl-02.login .txt {
  display: block;
}

/*--------------------------------------------
ページ概要
---------------------------------------------*/
.page-summary {
  padding-right: 5%;
  padding-left: 5%;
  margin-top: 25px;
}

/*--------------------------------------------
エラー警告文
---------------------------------------------*/
.error-anno {
  display: none;
}

.error-anno.show {
  display: block;
  width: 90%;
  padding: 16px 5%;
  border: 2px solid #f33434;
  border-radius: 4px;
  margin: 0 auto;
}

.error-anno.show .ttl-03 {
  display: flex;
  width: 100%;
  align-items: center;
  font-size: 18px;
  color: #f33434;
}

.error-anno.show .ttl-03 .icon {
  padding-right: 10px;
}

.error-anno.show .txt {
  font-size: 16px;
  margin-top: 10px;
  line-height: 1.8;
}

.u-layer .error-anno {
  width: 90%;
  margin: 30px auto 0;
}

/*--------------------------------------------
フォーム
---------------------------------------------*/
.form-wrap {
  margin-top: 30px;
}

.form-wrap .login-form {
  margin-top: 30px;
}

.form-wrap .customer-info {
  padding: 16px 5%;
  border: 1px solid #fbb901;
  background: #fdf0e1;
  border-radius: 4px;
}

.form-wrap .customer-info .ttl-03 {
  font-size: 18px;
}

.form-wrap .customer-info p {
  margin-top: 6px;
}

.form-wrap .customer-info p + p {
  margin-top: 4px;
}

.form-wrap .icon-req {
  font-size: 13px;
  color: #fff;
  line-height: 1;
  padding: 2px 6px;
  background: #f33434;
  border-radius: 4px;
  margin-right: 10px;
}

.form-wrap .block {
  margin-top: 24px;
}

.form-wrap .block .ttl {
  font-size: 18px;
}

.form-wrap .block .form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 4px;
}

.form-wrap .block .form.select .select-wrap {
  position: relative;
  width: 100%;
}

.form-wrap .block .form.select .select-wrap:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 6%;
  display: inline-block;
  width: 12px;
  height: 7.5px;
  background: url(/common/img/arw-b.png) no-repeat;
  -webkit-background-size: contain;
  background-size: contain;
  transform: translateY(-50%);
}

.form-wrap .block .form.select.relate .select-wrap {
  width: 60%;
}

.form-wrap .block .form.select.birth .select-wrap, .form-wrap .block .form.select.birth .year-wrap {
  width: 31%;
}

.form-wrap .block .form.select.birth .select-wrap:after, .form-wrap .block .form.select.birth .year-wrap:after {
  right: 10%;
}

.form-wrap .block .form.name > div {
  display: flex;
  flex-wrap: wrap;
  width: 48%;
}

.form-wrap .block .form.radio {
  justify-content: flex-start;
}

.form-wrap .block .form.rules {
  margin-top: 40px;
}

.form-wrap .block .form.rules .rules-box {
  font-size: 14px;
  padding: 18px 12px 18px 18px;
  background: #fef5d9;
  border: 2px solid #fbb901;
  border-radius: 6px;
}

.form-wrap .block .form.rules .rules-box .rules-inner {
  width: 100%;
  height: 156px;
  overflow-y: auto;
}

.form-wrap .block .form.rules .rules-summary {
  margin-top: 20px;
  line-height: 1.8;
}

.form-wrap .block .form.rules .rules-list {
  margin-top: 16px;
}

.form-wrap .block .form.rules .rules-list ul li {
  margin-top: 8px;
}

.form-wrap .block .form.rules .rules-list ul li .link {
  text-decoration: underline;
}

.form-wrap .block .form.rules .rules-list ul li > ul {
  margin-top: 4px;
}

.form-wrap .block .form.rules .rules-list ul li > ul li {
  padding-left: 1em;
  margin-top: 4px;
}

.form-wrap .block .form input[type='text'], .form-wrap .block .form input[type='password'], .form-wrap .block .form input[type='email'], .form-wrap .block .form textarea, .form-wrap .block .form select {
  display: block;
  width: 100%;
  font-size: 16px;
  margin-top: 4px;
}

.form-wrap .block .form .error-txt {
  display: none;
}

.form-wrap .block .form.error-wrap > input[type='text'], .form-wrap .block .form.error-wrap > input[type='password'], .form-wrap .block .form.error-wrap > input[type='email'], .form-wrap .block .form.error-wrap > textarea, .form-wrap .block .form.error-wrap > select {
  display: block;
  width: 100%;
  font-size: 16px;
  border: 1px solid #f33434;
  background: #fde1e1;
  margin-top: 4px;
}

.form-wrap .block .form.error-wrap .select-wrap.error input[type='text'], .form-wrap .block .form.error-wrap .select-wrap.error input[type='password'], .form-wrap .block .form.error-wrap .select-wrap.error input[type='email'], .form-wrap .block .form.error-wrap .select-wrap.error textarea, .form-wrap .block .form.error-wrap .select-wrap.error select {
  display: block;
  width: 100%;
  font-size: 16px;
  border: 1px solid #f33434;
  background: #fde1e1;
  margin-top: 4px;
}

.form-wrap .block .form.error-wrap .error input[type='text'], .form-wrap .block .form.error-wrap .error input[type='password'], .form-wrap .block .form.error-wrap .error input[type='email'], .form-wrap .block .form.error-wrap .error textarea, .form-wrap .block .form.error-wrap .error select {
  display: block;
  width: 100%;
  font-size: 16px;
  border: 1px solid #f33434;
  background: #fde1e1;
  margin-top: 4px;
}

.form-wrap .block .form.error-wrap .error-txt {
  display: block;
  width: 100%;
  color: #f33434;
}

.form-wrap .block .text {
  margin-top: 2px;
}

.form-wrap .block .text.name span + span, .form-wrap .block .text.kana span + span {
  margin-left: 16px;
}

.form-wrap .anno-txt {
  display: block;
  width: 100%;
  color: #adb0b9;
}

.form-wrap .agree, .form-wrap .retention {
  position: relative;
  display: table;
  width: auto;
  text-align: center;
  font-size: 16px;
  vertical-align: middle;
  margin: 30px auto 0;
  /*.box{
      position: relative;
      display: inline-block;
      width: 30px;
      height: 30px;
      border: 1px solid $tc;
      border-radius: 4px;
      margin-right: 15px;
    }
    input[type="checkbox"]:checked{
      + .box{
        &:before{
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          display: inline-block;
          width: 8px;
          height: 3px;
          background: $tc;
          transform: translate(calc(-50% - 5px), calc(-50% + 1px)) rotate(45deg);
        }
        &:after{
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          display: inline-block;
          width: 16px;
          height: 3px;
          background: $tc;
          transform: translate(calc(-50% + 3px), -50%) rotate(-45deg);
        }
      }
    }*/
}

.form-wrap .agree .text:before, .form-wrap .retention .text:before {
  content: '';
  position: relative;
  top: 7px;
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 1px solid #343e5d;
  border-radius: 4px;
  margin-right: 15px;
}

.form-wrap .agree .text:after, .form-wrap .retention .text:after {
  content: '';
  position: absolute;
  top: 52%;
  left: 20px;
  display: none;
  width: 8px;
  height: 13px;
  border-right: 3px solid #343e5d;
  border-bottom: 3px solid #343e5d;
  transform: translate(calc(-50% - 5px), calc(-50% + 1px)) rotate(45deg);
}

.form-wrap .agree input[type="checkbox"]:checked + .text:after, .form-wrap .retention input[type="checkbox"]:checked + .text:after {
  display: block;
}

.form-wrap .agree a, .form-wrap .retention a {
  text-decoration: underline;
}

.form-wrap .checkPass {
  position: relative;
  font-size: 13px;
  margin-top: 2px;
}

.form-wrap .checkPass label:before {
  content: '';
  position: relative;
  top: 6px;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid #343e5d;
  border-radius: 4px;
  margin-right: 8px;
}

.form-wrap .checkPass label:after {
  content: '';
  position: absolute;
  top: 54%;
  left: 16px;
  display: none;
  width: 6px;
  height: 10px;
  border-right: 2px solid #343e5d;
  border-bottom: 2px solid #343e5d;
  transform: translate(calc(-50% - 5px), calc(-50% + 1px)) rotate(45deg);
}

.form-wrap .checkPass input[type="checkbox"]:checked + label:after {
  display: block;
}

.form-wrap .radio-wrap {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.form-wrap .radio-wrap:before {
  content: '';
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
  background: #e1e2e7;
  border: 10px solid #e1e2e7;
  border-radius: 50%;
  margin-right: 8px;
}

.form-wrap .radio-wrap + input {
  margin-left: 40px;
}

.form-wrap input[type="radio"]:checked + .radio-wrap:before {
  background: #343e5d;
}

.form-wrap .btn.submit {
  margin-top: 30px;
}

.form-wrap .forget {
  text-align: right;
  margin-top: 18px;
}

.form-wrap .forget a {
  position: relative;
  display: inline-block;
  font-size: 16px;
  padding-bottom: 5px;
  padding-left: 14px;
  border-bottom: 1px solid #343e5d;
}

.form-wrap .forget a .icon {
  position: absolute;
  top: 46%;
  left: 0;
  transform: translateY(-50%);
}

/*--------------------------------------------
ログインページ
---------------------------------------------*/
.page-login .contents {
  padding-top: 0;
}

.page-login .form-wrap {
  margin-top: 0;
}

/*--------------------------------------------
施術内容
---------------------------------------------*/
.page-treatment .contents {
  padding-top: 40px;
  padding-bottom: 40px;
  background: #f0f0f3;
}

.page-treatment .contents .treatment-info {
  padding: 24px 5%;
  background: #fff;
  border-radius: 5px;
}

.page-treatment .contents .treatment-info + .treatment-info {
  margin-top: 30px;
}

.page-treatment .contents .treatment-info .date-wrap {
  display: flex;
  font-weight: bold;
}

.page-treatment .contents .treatment-info .img {
  margin-top: 16px;
}

.page-treatment .contents .treatment-info .trimmer {
  margin-top: 20px;
}

.page-treatment .contents .treatment-info .charge {
  padding-top: 20px;
  border-top: 1px solid #ffeab3;
  margin-top: 22px;
}

.page-treatment .contents .treatment-info .charge .ttl-03 {
  color: #fbb901;
}

.page-treatment .contents .treatment-info .charge .text {
  margin-top: 8px;
}

.page-treatment .page-nation-wrap {
  padding: 30px 5% 0;
}

.page-treatment .page-nation-wrap .count {
  text-align: center;
}

.page-treatment .page-nation-wrap .page-num-list {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.page-treatment .page-nation-wrap .page-num-list .list {
  width: 42px;
  height: 42px;
}

.page-treatment .page-nation-wrap .page-num-list .list + .list {
  margin-left: 10px;
}

.page-treatment .page-nation-wrap .page-num-list .list a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #e1e2e7;
  border-radius: 4px;
}

.page-treatment .page-nation-wrap .page-num-list .list.current a {
  background: #fbb901;
  color: #fff;
}

.page-treatment .page-nation-wrap .nav-send {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.page-treatment .page-nation-wrap .nav-send .list {
  width: 48%;
}

.page-treatment .page-nation-wrap .nav-send .list a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  background: #e1e2e7;
  border-radius: 4px;
}

.page-treatment .page-nation-wrap .nav-send .list a.prev:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 15px;
  width: 6px;
  height: 10px;
  background: url(/common/img/arw-l.png) no-repeat center/contain;
  transform: translateY(-50%);
}

.page-treatment .page-nation-wrap .nav-send .list a.next:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 15px;
  width: 6px;
  height: 10px;
  background: url(/common/img/arw-r.png) no-repeat center/contain;
  transform: translateY(-50%);
}

.page-treatment .page-nation-wrap .nav-send .list + .list {
  margin-left: 4%;
}

/*--------------------------------------------
よくあるご質問
---------------------------------------------*/
.page-faq .faq-area + .faq-area {
  margin-top: 32px;
}

.page-faq .faq-area .ttl-03 {
  font-size: 18px;
  font-weight: normal;
}

.page-faq .faq-area .faq-box {
  border: 1px solid #e1e2e7;
  border-radius: 5px;
  padding: 0;
  overflow: hidden;
}

.page-faq .faq-area .faq-box:first-of-type {
  margin-top: 18px;
}

.page-faq .faq-area .faq-box + .faq-box {
  margin-top: 30px;
}

.page-faq .faq-area .faq-box .ttl-04 {
  display: flex;
  padding: 16px 18px;
  border-bottom: 1px solid #e1e2e7;
}

.page-faq .faq-area .faq-box .ttl-04 .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background: #fbb901;
  border-radius: 50%;
  margin-right: 10px;
}

.page-faq .faq-area .faq-box .ttl-04 .icon i {
  color: #fff;
  font-size: 15px;
}

.page-faq .faq-area .faq-box .ttl-04 .text {
  width: calc(100% - 40px);
  font-size: 18px;
  font-weight: bold;
}

.page-faq .faq-area .faq-box .answer-box {
  padding: 24px 22px;
}

.page-faq .faq-area .faq-box .answer-box .inner + .inner {
  margin-top: 30px;
}

.page-faq .faq-area .faq-box .answer-box .inner .ttl-05 {
  display: flex;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
}

.page-faq .faq-area .faq-box .answer-box .inner .ttl-05 .icon {
  position: relative;
  top: 5px;
  margin-right: 4px;
}

.page-faq .faq-area .faq-box .answer-box .inner .ttl-05 + .text {
  margin-top: 4px;
}

.page-faq .faq-area .faq-box .answer-box .inner .text {
  line-height: 1.8;
}

.page-faq .faq-area .faq-box .answer-box .inner .text a {
  text-decoration: underline;
}

.page-faq .faq-area .faq-box .button-wrap {
  width: 100%;
  padding: 0;
}

.page-faq .faq-area .faq-box .button-wrap .button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 13px;
  padding: 12px 0;
  margin: 0;
  border: none;
}

.page-faq .faq-area .faq-box .button-wrap .button .icon {
  margin-right: 10px;
  font-weight: 900;
}

.page-faq .faq-area .faq-box .button-wrap .button.open {
  background: #e1e2e7;
}

.page-faq .faq-area .faq-box .button-wrap .button.close {
  display: none;
  color: #fff;
  background: #fbb901;
}

.page-faq .faq-area .faq-box.show {
  background: #fef5d9;
  border-color: #fbb901;
}

.page-faq .faq-area .faq-box.show .ttl-04 {
  border-color: #fbb901;
}

.page-faq .faq-area .faq-box.show .button-wrap {
  width: 100%;
}

.page-faq .faq-area .faq-box.show .button-wrap .button.open {
  display: none;
}

.page-faq .faq-area .faq-box.show .button-wrap .button.close {
  display: flex;
}

.page-faq .faq-area .faq-box.show .button-wrap .button.show {
  display: flex;
  justify-content: center;
  align-items: center;
}

/*--------------------------------------------
SE
---------------------------------------------*/
@media screen and (max-width: 320px) {
  /*--------------------------------------------
  ヘッダー(se)
  ---------------------------------------------*/
  .u-layer .header .logo-header img {
    width: 232px;
  }
  /*--------------------------------------------
  SPメニュー(se)
  ---------------------------------------------*/
  .sp-menu .customer-info .name-wrap .name {
    font-size: 16px;
  }
  .sp-menu .customer-info .name-wrap .kana {
    font-size: 12px;
  }
  /*--------------------------------------------
  ページタイトル(se)
  ---------------------------------------------*/
  .page-ttl {
    font-size: 18px;
  }
  /*--------------------------------------------
  フッター(se)
  ---------------------------------------------*/
  .footer .footer-tel {
    padding: 10px 5px;
  }
  .footer .footer-tel dl .ttl {
    padding-right: 10px;
  }
  .footer .footer-tel dl .num {
    padding-left: 10px;
  }
  .footer .footer-tel a {
    font-size: 14px;
  }
}

@media print {
  * {
    background: none !important;
    color: black !important;
    box-shadow: none !important;
    text-shadow: none !important;
    filter: Gray();
    filter: url("desaturate.svg#grayscale");
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
  }
  a {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  a[href="#"],
  a[href="javascript:"] {
    content: "";
  }
}
