@charset "utf-8";

//===================================================================
//    プリント設定
//===================================================================

@media print {
    * {
        background: none !important;
        color: black !important;
        box-shadow: none !important;
        text-shadow: none !important;

        filter: Gray();
        filter: url('desaturate.svg#grayscale');
        -webkit-filter: grayscale(100%);
        -moz-filter: grayscale(100%);
        -ms-filter: grayscale(100%);
        -o-filter: grayscale(100%);
        filter: grayscale(100%);
    }

    a {
        text-decoration: underline;
    }

    a[href]:after {
        content: " (" attr(href) ")";
    }

    a[href="#"],
    a[href="javascript:"] {
        content: "";
    }
}