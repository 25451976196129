@charset "utf-8";
//============================================================
//      フォント設定
//============================================================
@font-face{
  font-family: "Original Yu Gothic";
  src: local("Yu Gothic");
  font-weight: 300;
}

@font-face{
  font-family: "Original Yu Gothic";
  src: local("Yu Gothic");
  font-weight: 500;
}

@font-face{
  font-family: "Original Yu Gothic";
  src: local("Yu Gothic");
  font-weight: bold;
}
@font-face {
  font-family: "Helvetica Neue";
  src: local("Helvetica Neue Regular");
  font-weight: 100;
}
@font-face {
  font-family: "Helvetica Neue";
  src: local("Helvetica Neue Regular");
  font-weight: 200;
}

/* IE10以上 */
@media all and (-ms-high-contrast: none)  {
  html {
    font-family: Verdana, Meiryo, sans-serif;
  }
}
@media all and (-ms-high-contrast: active) {
  html {
    font-family: Verdana, Meiryo, sans-serif;
  }
}
